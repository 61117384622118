
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import RVSCalendarService from '../../rvs-calendar.service';
import RateValueScoreService from '../../rate-value-score.service';
import ScoreCard from '../_score-card.vue';

@Component({ components: { ScoreCard } })
export default class RVSRootCauseAnalysis extends Vue {
    @inject(KEY.RVSCalendarService) private rvsCalendarService!: RVSCalendarService;
    @inject(KEY.RateValueScoreSerivce) private rateValueScoreService!: RateValueScoreService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    get date() {
        const { year, month } = this.documentFiltersService;
        const { currentDay } = this.rvsCalendarService.storeState;

        return moment(new Date(year, month, currentDay)).format('DD.MM.YYYY');
    }

    get rootCause() {
        const { currentRootCause } = this.rvsCalendarService;
        if (!currentRootCause) return [];

        return Object.entries(currentRootCause).sort((a, b) => b[1] - a[1]);
    }

    get labels() {
        return this.rootCause.map(([label]) => label);
    }

    get colors() {
        const { chartColors } = this.settingsGeneralService;

        return this.rootCause.map((_, index) => chartColors[index % chartColors.length]);
    }

    get isLoading() {
        return this.rvsCalendarService.isLoading;
    }

    get isNoScore() {
        if (this.isLoading) return false;

        const { currentDay } = this.rvsCalendarService.storeState;
        const doc = this.rvsCalendarService.data;

        return this.rateValueScoreService.isNoData(currentDay, doc)
            || !this.rateValueScoreService.getMainValue(currentDay, doc);
    }
}
