var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ScoreCard',{staticClass:"rvs-score-container",class:_vm.color,attrs:{"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('h2',{staticClass:"rvs-overview__block-headline"},[_vm._v(" "+_vm._s(_vm.$tc('rvs.score.title'))+" ")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                header: _vm.$tc('rvs.score.hintMessageTitle'),
                text: _vm.tooltipText,
                position: 'right',
            }),expression:"{\n                header: $tc('rvs.score.hintMessageTitle'),\n                text: tooltipText,\n                position: 'right',\n            }"}],staticClass:"icon-Question-mark"})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"rvs-score-container__score"},[(_vm.hasData)?[_c('span',{staticClass:"rvs-score-container__score-main",attrs:{"data-label":_vm.$t('rvs.score.mainScoreTitle')}},[_vm._v(" "+_vm._s(_vm.mainScore)+" ")]),_c('span',{staticClass:"rvs-score-container__score-divider"},[_vm._v(" vs ")]),_c('span',{staticClass:"rvs-score-container__score-avg",attrs:{"data-label":_vm.$t('rvs.score.avgScoreTitle')}},[_vm._v(" "+_vm._s(_vm.averageCompsetScore)+" ")]),_c('div',{staticClass:"rvs-score-container__grow-indicator",class:{
                        'rvs-score-container__grow-indicator--up': _vm.difference > 0,
                        'rvs-score-container__grow-indicator--down': _vm.difference < 0,
                    }},[_vm._v(" "+_vm._s(_vm.difference)+"% ")])]:_c('p',{staticClass:"rvs-score-container__no-data-label"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])],2),(_vm.difference > 0)?_c('p',{staticClass:"rvs-score-container__score-message"},[_vm._v(" "+_vm._s(_vm.$tc('rvs.score.higherMessage', 0, [_vm.mainScore, Math.abs(_vm.difference)]))+" ")]):_vm._e(),(_vm.difference < 0)?_c('p',{staticClass:"rvs-score-container__score-message"},[_vm._v(" "+_vm._s(_vm.$tc('rvs.score.lowerMessage', 0, [_vm.mainScore, Math.abs(_vm.difference)]))+" ")]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }